<template>
  <div id="app">
    <div
      :style="{
        backgroundImage: 'url(' + $organization.background_image + ')',
      }"
      class="bg"
    ></div>
    <div id="loader" class="loader"></div>
    <div
      id="adminlte"
      ref="adminlte"
      :class="['hold-transition sidebar-mini sidebar-collapse']"
    >
      <div class="wrapper">
        <main-header :user="user"></main-header>
        <sidebar :user="user"></sidebar>
        <div class="content-wrapper text-sm">
          <div class="content">
            <div class="container-fluid my-4">
              <nuxt />
            </div>
          </div>
        </div>
        <footer class="main-footer text-sm">
          <div class="float-right d-none d-sm-inline"></div>
          <span>
            Copyright &copy; {{ new Date().getFullYear() }} All rights reserved
            | Développé par <a target="_blank" href="https://tik.tn/">Tik</a>
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '~/common/components/Dashboard/sidebar'
import MainHeader from '~/common/components/Dashboard/header'
import { mapGetters } from 'vuex'

export default {
  components: {
    Sidebar,
    MainHeader,
  },
  middleware: ['auth', 'session', 'tracking'],
  computed: {
    ...mapGetters({ user: 'auth/user' }),
  },
  watch: {
    $route(to, from) {
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    },
  },
  matomo: {
    trackTimeSpent: ['enableHeartBeatTimer'],
  },
}
</script>

<style>
html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.margin-l-0 {
  margin-left: 0 !important;
}
.v-select {
  padding-top: 0;
}
.v-select input {
  height: 20px;
}
.progress-bar-light-blue,
.progress-bar-primary {
  background-color: #3c8dbc !important;
}
.progress-bar-green,
.progress-bar-success {
  background-color: #00a65a !important;
}
.progress-bar-aqua,
.progress-bar-info {
  background-color: #00c0ef !important;
}
.progress-bar-yellow,
.progress-bar-warning {
  background-color: #f39c12 !important;
}
.progress-bar-red,
.progress-bar-danger {
  background-color: #dd4b39 !important;
}
.progress-bar {
  color: black;
}
td .progress {
  margin-top: auto;
  margin-bottom: auto;
}
.justify-center {
  display: flex;
  align-items: center;
  float: none;
  vertical-align: middle;
}

.btn-circle {
  text-align: center;
  border-radius: 50%;
}
.v-datatable .v-menu .v-menu__content.menuable__content__active {
  top: 0 !important;
  left: 0 !important;
}
.schedule-menu .v-menu__content {
  right: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  top: auto !important;
}
.status.btn-group {
  margin-right: 20px;
}
.status.btn-group .btn {
  opacity: 1;
}
.calendar {
  z-index: 100 !important;
}
.calendar-root .input-date,
.dropdown.v-select .dropdown-toggle {
  background-color: white;
}

.bg {
  background-size: cover;
  background-color: #efefef;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.app,
.wrapper,
.content-wrapper {
  background: rgba(0, 0, 0, 0) !important;
}

.info-box {
  background-color: #0f070780;
  color: white;
}

.products-list .product-description {
  white-space: normal;
}
.v-datatable--select-all th:first-of-type,
.v-datatable--select-all td:first-of-type {
  width: 24px;
  padding-right: 0px !important;
}
.navbar-left {
  float: left !important;
}

.navbar-left .nav {
  float: left !important;
}
.loader {
  display: none;
  height: 8px;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  background-color: #ddd;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 99999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}
body.loading .loader {
  display: block;
}
.loader::before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 8px;
  background-color: #2196f3 !important;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
.login-box {
  margin-top: 0;
}
.login-logo {
  padding-top: 7rem;
}
.btn-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: '..';
}
input.form-control[type='number'] {
  max-width: 100% !important;
}
.line-through {
  text-decoration: line-through;
}
</style>
