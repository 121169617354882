<template>
  <li v-if="canView(name)" class="nav-item">
    <router-link
      :to="to || { name }"
      class="nav-link"
      @click.native="hideSidebar()"
    >
      <fa class="nav-icon" :icon="icon" fixed-width />
      <p>
        <slot></slot>
      </p>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarItem',
  props: ['name', 'to', 'icon'],
  computed: {
    ...mapGetters({ user: 'auth/user' }),
  },
  methods: {
    hideSidebar() {
      /* document.body.classList.remove("sidebar-open"); */
    },
  },
}
</script>
