import { organization as $organization, setConfigOrganization } from '~/sdk'

export default async function refreshOrganization({ route, app }) {
  if (process.client) {
    if ('live' in route.query) {
      console.log('Refreshing organization data...')
      await $organization.refresh('REFERER')
      setConfigOrganization($organization)
    }
  }
}
