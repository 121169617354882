/* eslint-disable no-unused-vars */
const ALL_ROLES = [
  'admin',
  'manager',
  'finance',
  'suivi',
  'commercial',
  'stock',
]
const ACTIVE_ROLES = ['admin', 'manager', 'finance', 'suivi', 'commercial']
const TOP_ADMINS = ['admin', 'manager']
const SUIVIS = ['admin', 'manager', 'finance', 'suivi']
const COMMERCIALS = ['admin', 'manager', 'finance', 'commercial']
const STOCK_TEAM = ['admin', 'manager', 'stock']
const POS_TEAM = ['admin', 'manager', 'pos']
/* eslint-enable no-unused-vars */

const AUTH = {
  index: true,

  clients: COMMERCIALS,
  'clients-add': COMMERCIALS,
  'clients-id': COMMERCIALS,

  staffs: ['admin', 'manager', 'finance'],
  'staffs-add': ['admin'],
  'staffs-id': TOP_ADMINS,
  'reports-staffs': TOP_ADMINS,

  // Products
  products: ACTIVE_ROLES,
  'products-id': ACTIVE_ROLES,
  'products-add': TOP_ADMINS,

  // Stores
  stores: ['admin', 'manager', 'finance'],
  'stores-id': ['admin', 'manager', 'finance'],
  'stores-add': TOP_ADMINS,

  // Commands
  commands: { module: 'commands', roles: ACTIVE_ROLES },
  'commands-add': { module: 'commands', roles: COMMERCIALS },
  'clients-clientId-commands-add': { module: 'commands', roles: COMMERCIALS },
  'commands-id': { module: 'commands', roles: ACTIVE_ROLES.concat(['stock']) },
  suivis: { module: 'commands', roles: SUIVIS },
  'suivis-expired': { module: 'commands', roles: SUIVIS },
  'commands-rapport-status': { module: 'commands', roles: ACTIVE_ROLES },

  // Orders
  'orders-id': { module: 'commands', roles: ACTIVE_ROLES.concat(['stock']) },
  'orders-id-payments-token-success': { module: 'commands', roles: true },
  'orders-id-payments-token-failure': { module: 'commands', roles: true },

  // Delivery
  'delivery-add': { module: 'commands', roles: TOP_ADMINS },
  delivery: { module: 'commands', roles: ['admin', 'manager', 'finance'] },
  'delivery-id': { module: 'commands', roles: ['admin', 'manager', 'finance'] },
  'delivery-rapport-id': { module: 'commands', roles: COMMERCIALS },

  // Stock
  'stock-report': {
    module: 'commands',
    roles: STOCK_TEAM.concat(['suivi', 'finance']),
  },
  stock: { module: 'commands', roles: STOCK_TEAM },
  'stock-dashboard': { module: 'commands', roles: STOCK_TEAM },
  'stock-return': { module: 'commands', roles: STOCK_TEAM },

  // Collection
  collections: { module: 'collections', roles: ACTIVE_ROLES },
  'collections-add': { module: 'collections', roles: TOP_ADMINS },
  'collections-id': { module: 'collections', roles: ACTIVE_ROLES },

  // POS
  pos: { module: 'pos', roles: POS_TEAM },

  // Instructors
  instructors: ['admin', 'manager', 'finance', 'instructor'],
  'instructors-add': ['admin', 'manager'],
  'instructors-id': TOP_ADMINS,

  // Students
  students: ['admin', 'manager', 'finance', 'instructor'],
  'students-add': ['admin', 'manager', 'instructor'],
  'students-id': TOP_ADMINS,

  // Enrolls
  enrolls: ['admin', 'manager', 'finance', 'instructor'],
  'enrolls-add': ['admin', 'manager'],
  'enrolls-id': TOP_ADMINS,

  // Courses
  courses: ['admin', 'manager', 'instructor'],
  'courses-add': ['admin', 'manager', 'instructor'],
  'courses-id': ['admin', 'manager', 'instructor'],

  // Lessons
  'courses-course_id-lessons-add': ['admin', 'manager', 'instructor'],
  'courses-course_id-lessons-id': ['admin', 'manager', 'instructor'],

  // Meetings
  meetings: ['admin', 'manager', 'instructor'],
  'meetings-add': ['admin', 'manager', 'instructor'],
  'meetings-id': ['admin', 'manager', 'instructor'],

  // Account
  'account': true,
  'account-edit': true,
  'account-activate': true,
  'account-password-confirm': true,
  'account-password-reset': true,
  'account-email-confirm': true,
}

const hasViewPerm = ($org, user, routeName) => {
  const roles = AUTH[routeName]
  if (roles === true) {
    return true
  } else if (Array.isArray(roles)) {
    return roles.some((r) => user.roles.includes(r))
  } else if (typeof roles === 'object' && roles !== null) {
    return (
      $org.hasModule(roles.module) &&
      roles.roles.some((r) => user.roles.includes(r))
    )
  } else {
    return false
  }
}

export default hasViewPerm
