import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import pkg from '~/package.json'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a5f60263e9614711b6a1eb7210bcf884@sentry.io/1353747',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
    release: pkg.name + '@' + pkg.version,
  })
}
