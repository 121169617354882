import Vue from 'vue'

if (typeof Vue.config.optionMergeStrategies.static !== 'function') {
  Vue.config.optionMergeStrategies.static =
    Vue.config.optionMergeStrategies.data
}

Vue.mixin({
  beforeCreate() {
    const _static = this.$options.static
    if (!this.$static) {
      this.$static = {}
    }
    if (_static && typeof _static === 'function') {
      Object.assign(this.$static, _static.apply(this))
    } else if (_static && typeof _static === 'object') {
      Object.assign(this.$static, _static)
    }
  },
})
