import * as $sdk from '~/sdk'

/**
 * TODO
 *
 * await staffsApi.save(this.student)
 * found = await authApi.login(this.student.email, this.student.password)
 * store.user = await authApi.currentUser()
 */

export const state = () => ({
  user: null,
  token: null,
})

export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setToken(state, token) {
    state.token = token
  },
}

export const actions = {
  setUser({ commit }, user) {
    commit('setUser', user)
  },
  setToken({ commit }, token) {
    commit('setToken', token)
  },
  async logout({ commit }) {
    await $sdk.core.auth.logout()
    commit('setUser', null)
    commit('setToken', null)
  },
  async loadCurrentUser({ commit, dispatch }) {
    if (await $sdk.core.auth.isLoggedin()) {
      const user = await $sdk.core.auth.currentUser()
      dispatch('setUser', user)
    } else {
      dispatch('setUser', null)
    }
  },
  async login({ commit, dispatch }, { email, password }) {
    const found = await $sdk.core.auth.login(email, password)
    if (found) {
      const user = await $sdk.core.auth.currentUser()
      dispatch('setUser', user)
    } else {
      dispatch('setUser', null)
    }
  },
  async register({ commit, state, dispatch }, user) {
    if (state.user) {
      await dispatch('logout')
    }
    user = await $sdk.crm.staffs.save(user)
    return user
  },
}

export const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
}
