<template>
  <nav
    class="main-header navbar navbar-expand navbar-white navbar-light text-sm"
  >
    <ul class="navbar-nav">
      <li class="nav-item sidebar-toggle">
        <a class="nav-link" role="button" @click.prevent="toggleSidebar()"
          ><i class="fa fa-bars"></i
        ></a>
      </li>
      <b-nav-item-dropdown no-caret>
        <template #button-content>
          <i class="fa fa-ellipsis-v"></i>
        </template>
        <menu-item name="staffs" icon="user-secret"
          >Staffs
          <span v-if="unlimited" class="badge right badge-yellow"
            >{{ unlimited }}
          </span>
        </menu-item>
        <menu-item name="stores" icon="building">Boutiques</menu-item>
        <menu-item name="collections" icon="th">Collections</menu-item>
        <menu-item
          v-if="
            ['admin', 'manager', 'finance', 'commercial'].includes(user.role)
          "
          :to="{
            name: 'commands-rapport-status',
            params: { status: 'scheduled' },
            /*query: { scheduled: 'attented' }*/
          }"
          name="commands-rapport-status"
          icon="hourglass-end"
          >Réservations à confirmer</menu-item
        >
        <menu-item name="delivery" icon="cubes">Sociétés Livraison</menu-item>
        <menu-item name="suivis-expired" icon="exclamation-triangle"
          >Suivis Expirés</menu-item
        >
        <menu-item name="stock-report" icon="th-list">Rapport Stock</menu-item>
        <menu-item name="stock" icon="exchange-alt"
          >Libération Commandes</menu-item
        >
        <menu-item name="stock-return" icon="times">Retour Depot</menu-item>
      </b-nav-item-dropdown>
      <menu-item name="clients" icon="users">Clients</menu-item>
      <menu-item name="products" icon="barcode">Produits</menu-item>
      <menu-item name="commands" icon="shopping-cart">Commands</menu-item>
      <menu-item name="suivis" icon="truck">Suivis</menu-item>
      <menu-item name="pos" icon="desktop">POS</menu-item>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item d-md-down-none">
        <a
          v-show="install"
          ref="install"
          :style="{ background: '#3c8dbc', display: 'none' }"
          class="nav-link"
          role="button"
          title="Install"
          @click="installApp()"
          ><i class="fa fa-download"></i> Install</a
        >
      </li>
      <li class="nav-item d-md-down-none">
        <span class="nav-link">
          <Screenfull />
        </span>
      </li>
      <!-- User Account Menu -->
      <b-nav-item-dropdown :class="['user', 'user-menu']" no-caret right>
        <template #button-content>
          <!-- The user image in the navbar-->
          <img
            :src="require('admin-lte/dist/img/avatar5.png')"
            class="user-image"
            alt="User Image"
          />
          <!-- hidden-xs hides the username on small devices so only the image appears. -->
          <span class="d-none d-sm-inline-block">{{ user.full_name }}</span>
        </template>
        <li class="user-header">
          <img
            :src="require('admin-lte/dist/img/avatar5.png')"
            class="img-circle"
            alt="User Image"
          />

          <p>
            <nuxt-link :to="{ name: 'account' }" class="text-reset">
              {{ user.full_name }} - {{ user.role }}
            </nuxt-link>
            <small>{{ user.email }}</small>
            <small>{{ user.phone }}</small>
          </p>
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
          <router-link :to="{ name: 'logout' }" class="btn btn-block btn-danger"
            >Sign out</router-link
          >
        </li>
      </b-nav-item-dropdown>
    </ul>
  </nav>
</template>
<script>
import staffsApi from '~/sdk/src/crm/staffs'
import Screenfull from './Screenfull'
import MenuItem from './header-item'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  components: {
    Screenfull,
    MenuItem,
  },
  data: () => ({
    install: null,
    unlimited: 0,
  }),
  computed: {
    ...mapGetters({ user: 'auth/user' }),
  },
  async mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.install = e
    })
    setTimeout(() => {
      const adminlte = document.getElementById('adminlte')
      if (localStorage.sidebarCollapse === '0') {
        adminlte.classList.remove('sidebar-collapse')
      } else {
        adminlte.classList.add('sidebar-collapse')
      }
    }, 100)
    if (this.user.is_admin) {
      this.unlimited = await staffsApi.unlimited()
    }
  },
  methods: {
    installApp() {
      this.install.prompt()
      // Wait for the user to respond to the prompt
      this.install.userChoice.then((choiceResult) => {
        this.install = null
      })
    },
    toggleSidebar() {
      const adminlte = document.getElementById('adminlte')
      adminlte.classList.toggle('sidebar-collapse')
      if (adminlte.classList.contains('sidebar-collapse')) {
        localStorage.sidebarCollapse = '1'
      } else {
        localStorage.sidebarCollapse = '0'
      }
    },
    hideSidebar() {
      /* document.body.classList.remove("sidebar-open"); */
    },
  },
}
</script>

<style>
@media (max-width: 767px) {
  .sidebar-toggle {
    display: none;
  }
}
</style>
