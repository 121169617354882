<template>
  <b-dropdown-item
    v-if="canView(name)"
    class="nav-item"
    link-class="nav-link"
    :to="to || { name }"
    @click.native="hideSidebar()"
  >
    <fa class="nav-icon" :icon="icon" fixed-width />
    <p class="nav-text">
      <slot></slot>
    </p>
  </b-dropdown-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderItem',
  props: ['name', 'to', 'icon'],
  computed: {
    ...mapGetters({ user: 'auth/user' }),
  },
  methods: {
    hideSidebar() {
      /* document.body.classList.remove("sidebar-open"); */
    },
  },
}
</script>
<style>
.main-header > .navbar-nav > .nav-item > .nav-link > .nav-text {
  display: none;
}
.main-header > .navbar-nav .dropdown-menu > .nav-item > .nav-link > .nav-text {
  display: inline;
}
</style>
