<template>
  <li v-if="!name || canView(name)" class="nav-item">
    <a href="#" class="nav-link" @click.prevent="toggle()">
      <fa class="nav-icon" :icon="icon" fixed-width />
      <p>
        {{ title }}
        <fa
          :icon="show ? 'angle-down' : 'angle-left'"
          class="right"
          fixed-width
        />
      </p>
    </a>
    <b-collapse v-model="show" is-nav tag="ul" class="nav nav-pills">
      <slot></slot>
    </b-collapse>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarSection',
  props: ['name', 'title', 'to', 'icon'],
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters({ user: 'auth/user' }),
  },
  methods: {
    toggle() {
      this.show = !this.show
      this.hideSidebar()
    },
    hideSidebar() {
      /* document.body.classList.remove("sidebar-open"); */
    },
  },
}
</script>
