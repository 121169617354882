import * as $sdk from '~/sdk'

let wasFetched = false

export default async function fetch_organization({ env, app, error }) {
  if (wasFetched) return
  if (process.client && env.SDK_FETCH_MODE === 'client') {
    try {
      if (env.BASE_URL) {
        let base_url = process.env.BASE_URL
        base_url = new URL(base_url)
        base_url = base_url.hostname
        await $sdk.organization.refresh('DOMAIN', base_url)
      } else {
        await $sdk.organization.refresh('REFERER')
      }
    } catch (e) {
      error({
        statusCode: 404,
        message: 'Something went wrong, but don’t fret — it’s not your fault.',
      })
    }
    $sdk.setConfigOrganization($sdk.organization)
    wasFetched = true
  }
}
