<template>
  <aside
    class="main-sidebar sidebar-dark-primary elevation-4 sidebar-no-expand"
  >
    <router-link :to="{ name: 'index' }" class="brand-link text-center text-sm">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <span class="brand-text font-weight-bold sm"
        ><b>{{ $organization.short_name[0].toUpperCase() }}</b></span
      >
      <!-- logo for regular state and mobile devices -->
      <span class="brand-text font-weight-light lg"
        ><b>{{ $organization.short_name }}</b></span
      >
    </router-link>

    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <router-link :to="{ name: 'index' }" @click.native="hideSidebar()">
          <img :src="$organization.logo" class="img-fluid" />
        </router-link>
      </div>

      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <menu-item name="clients" icon="users">Clients</menu-item>

          <menu-item name="products" icon="barcode">Produits</menu-item>
          <menu-item name="collections" icon="th">Collections</menu-item>
          <menu-item name="commands" icon="shopping-cart">Commands</menu-item>
          <menu-item name="suivis" icon="truck">Suivis</menu-item>
          <menu-item
            v-if="
              ['admin', 'manager', 'finance', 'commercial'].includes(user.role)
            "
            :to="{
              name: 'commands-rapport-status',
              params: { status: 'scheduled' },
              /*query: { scheduled: 'attented' }*/
            }"
            name="commands-rapport-status"
            icon="hourglass-end"
            >Réservations à confirmer</menu-item
          >
          <menu-item name="suivis-expired" icon="exclamation-triangle"
            >Suivis Expirés</menu-item
          >
          <menu-item name="stock-report" icon="th-list"
            >Rapport Stock</menu-item
          >

          <menu-item name="stock" icon="exchange-alt"
            >Libération Commandes</menu-item
          >
          <menu-item name="stock-return" icon="times">Retour Depot</menu-item>
          <menu-item name="pos" icon="desktop">POS</menu-item>

          <menu-section title="Advanced" icon="cogs">
            <menu-item name="staffs" icon="user-secret"
              >Staffs
              <span v-if="unlimited" class="badge right badge-yellow"
                >{{ unlimited }}
              </span>
            </menu-item>
            <menu-item name="delivery" icon="cubes"
              >Sociétés Livraison</menu-item
            >
            <menu-item name="stores" icon="building">Boutiques</menu-item>
          </menu-section>
        </ul>
      </nav>
    </div>
  </aside>
</template>
<script>
import staffsApi from '~/sdk/src/crm/staffs'
import MenuItem from './sidebar-item'
import MenuSection from './sidebar-section'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  components: {
    MenuItem,
    MenuSection,
  },
  data: () => ({
    unlimited: 0,
  }),
  computed: {
    ...mapGetters({ user: 'auth/user' }),
  },
  async mounted() {
    if (this.user.is_admin) {
      this.unlimited = await staffsApi.unlimited()
    }
  },
  methods: {
    hideSidebar() {
      /* document.body.classList.remove("sidebar-open"); */
    },
  },
}
</script>

<style>
.sidebar-mini:not(.sidebar-collapse) .brand-link .brand-text.sm {
  display: none;
}

.sidebar-mini.sidebar-collapse .brand-link .brand-text.sm {
  display: block;

  width: auto;

  padding: 0;

  margin: 0;

  animation: none;

  visibility: visible;
}

.sidebar-mini:not(.sidebar-collapse) .brand-link .brand-text.lg {
  display: block;
}

.sidebar-mini.sidebar-collapse .brand-link .brand-text.lg {
  display: none;
}
.sidebar-mini:not(.sidebar-collapse) .user-panel img {
  padding: 0 2rem;

  width: auto;
}

.sidebar-mini.sidebar-collapse .user-panel img {
  padding: 0 0.5rem;

  width: auto;
}
</style>
